<template>
  <div class="info__block">
  <b-table
      class="medic__table"
     :fields="fields"
     :items = "items"
     :sort-by.sync="sortBy"
     :sort-desc.sync="sortDesc"
     >

     <template #cell(routeNumber)="data">
         <span style="font-size: 14px; line-height: 18px; font-weight: 500;">
            <span> 00000{{ data.item.routeNumber }}</span>
         </span>
    </template>

     <template #cell(status)="data">
            <span v-if="data.item.status == 1" style="background: #E0E9F9; padding: 5px 10px; border-radius: 3px; color:#3B6FD4;font-weight: 500;font-size: 12px;line-height: 14px;">
                Новый  
            </span>
            <span v-if="data.item.status == 3" style="background: #FBD2CE; padding: 5px 10px; border-radius: 3px; color:#E74B3B;font-weight: 500;font-size: 12px;line-height: 14px;">
                Отказ  
            </span>
            <span v-if="data.item.status == 2" style="background: #DCFFE4; padding: 5px 10px; border-radius: 3px; color: #28A745;font-weight: 500;font-size: 12px;line-height: 14px;">
               Одобрен  
            </span>
    </template>

     <template #cell(reference)="data">
            <svg v-show="data.item.status == 2 || data.item.status == 3" type="submit"  width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" @click="exportReference(data.item.routeNumber)">
              <path d="M20 12.5V6.8C20 5.11984 20 4.27976 19.673 3.63803C19.3854 3.07354 18.9265 2.6146 18.362 2.32698C17.7202 2 16.8802 2 15.2 2H8.8C7.11984 2 6.27976 2 5.63803 2.32698C5.07354 2.6146 4.6146 3.07354 4.32698 3.63803C4 4.27976 4 5.11984 4 6.8V17.2C4 18.8802 4 19.7202 4.32698 20.362C4.6146 20.9265 5.07354 21.3854 5.63803 21.673C6.27976 22 7.1198 22 8.79986 22H12.5M14 11H8M10 15H8M16 7H8M15 19L18 22M18 22L21 19M18 22V16" stroke="#3F8AE0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
    </template>
      </b-table>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import axios from 'axios';

   export default {
      props: {

      },
      data() {
        return {
            sortBy: 'age',
            sortDesc: true,
            fields :[
                { 
                    key: 'sendTime',
                    label: 'Дата отъезда',
                    sortable: true,
                    class: "medic__table.b-table",
                },
                { 
                    key: 'routeNumber',
                    label: 'Номер маршрута',
                    sortable: true,
                    class: "medic__table",
                },
                { 
                    key: 'timeInRoad',
                    label: 'Время в пути',
                    sortable: true,
                    class: "medic__table",
                },
                { 
                    key: 'driver',
                    label: 'Водитель',
                    sortable: true,
                    class: "medic__table",
                },
                {
                     key: 'iin',
                     label: 'ИИН',
                     sortable: true,
                     class: "medic__table",
                },
                {
                     key: 'status',
                     label: 'Статус',
                     sortable: true,
                     class: "medic__table",
                },
                {
                     key: 'reference',
                     label: 'Справка',
                     class: "medic__table",
                }   
            ],
             items : []
        }
      },
      methods: {
        
        async exportReference(order_id){
          await axios({
            method: 'get',
            url : process.env.VUE_APP_API_URL + `/web/export-route-reference?order_id=${order_id}`,
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
            },
            responseType: 'blob'
          }).then(response => {
            const blob = new Blob([response.data], {
              type: 'application/pdf'
            });
            var url = window.URL.createObjectURL(blob)
            window.open(url);
          }).catch(function(error) {
            console.log(error)
            })
        }
      },
      computed: {
        ...mapState(['driverInfo'])
      },
      mounted() {
        if(this.driverInfo.driver_orders){
        const driverData =  this.driverInfo[0];
        const orders = this.driverInfo.driver_orders
          orders.forEach(el => {
            this.items.push({
                sendTime : el.start_date,
                routeNumber : el.id,
                timeInRoad : el.driving_time,
                driver : driverData.name,
                iin : driverData.iin,
                status : el.driver_health_status
            })
          });
       }
      },
   }
</script>

<style scoped>
.header {
  padding: 15px 30px;
  background: #fff;
  height: 70px;
}

.medic-header{
    height: 70px;
    width: 100%;
    background: white;
    border: solid 2px lightgray;
    text-align: center;
}
.header__logo {
  height: 40px;
  cursor: pointer;
}
.header__menu {
  padding-top: 20px;
}

.header__menu-item {
  border-bottom: 1px solid #00000000;
  cursor: pointer;
  margin-right: 20px;
}

.header__right-user {
  float: right;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background-color: #e0e9fa;
  cursor: pointer;
}

.header__right-icon {
  fill: #acacac;
  cursor: pointer;
}
.medic__table {
    margin-left: 0;
    margin-right: 0;
    margin-top: 20px;
    width: 100%;
    margin-bottom: 30px;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
}

</style>