<template>
  <div class="main"> 
    <div>
        <h3 class="driver__info">Аналитика рейсов</h3>
        <hr>
        <div class="driver__analitic flex">
          <ul class="driver__analitic-items">
            <li class="driver__analitic-item">
                <svg class="rect1" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="14" height="14" rx="4" fill="#28A745"/>
                </svg> 
                <span class="span1">{{ executedOrder }}</span> <br>
                <span class="driver__analitic-span1">Выполненных рейсов</span> <span class="driver__analitic-span2"> {{ Math.floor(executedOrderPercent) }}%</span>
            </li>
            <li class="driver__analitic-item">
              <svg class="rect1" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="14" height="14" rx="4" fill="#FBD2CE"/>
              </svg>
              <span class="span1">{{ refusedOrder }}</span> <br>
              <span class="driver__analitic-span1">Отказано в маршруте</span> <span class="driver__analitic-span2"> {{ Math.floor(refusedOrderPercent) }}%</span>
            </li>
            <li class="driver__analitic-item">
              <svg class="rect1" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="14" height="14" rx="4" fill="#E74B3B"/>
              </svg>
              <span class="span1">{{ refusedBreathalyzer }}</span> <br>
              <span class="driver__analitic-span1">Отказано алкотестом</span>
              <span class="driver__analitic-span2">  {{ Math.floor(refusedBreathalyzerPercent) }}%</span>
            </li>
          </ul>
          <div class="driver__analitic-chart">
            <apexchart type="donut" height="350" :options="options" :series="series"/>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import VueApexCharts from "vue-apexcharts";

 export default {
  data() {
    return {
      executedOrder : 0,
      refusedOrder: 0,
      refusedBreathalyzer: 0,
      executedOrderPercent: 0,
      refusedOrderPercent: 0,
      refusedBreathalyzerPercent: 0,
      count: 2
    }
  },
  components: {
    apexchart: VueApexCharts,
  },
  computed: {
    ...mapState(['driverInfo']),
    series() {
      return [
        this.executedOrder, this.refusedOrder, this.refusedBreathalyzer,
      ];
    },
    options() {
      return {
        labels: ['Выполненных рейсов', 'Отказано в маршруте', 'Отказано алкотестом'],
        chart: {
          type: 'donut'
        },
        colors: [ '#28A745', '#FBD2CE', '#E74B3B'],
        dataLabels: {
          enabled: false   
        },
        plotOptions: {
          pie: {
            size: '500',
            customScale: 0.8,
            donut: {
              size: '85%',
              labels: {
                show: true,
                total: {
                  show: true,
                  label: "Общее количество",
                  color: '#000000',
                  fontWeight: '500',
                  fontSize: '18px',
                  lineHeight: '18px',
                  fontFamily: 'Google Sans'
                },
                value: {
                  show : true,
                  fontWeight: '500',
                  fontSize: '30px',
                  lineHeight: '38px',
                },
              }
            }
          }
        }
      }
    },
  },
  mounted() { 
    this.executedOrder
    this.driverInfo.driver_orders.forEach(element => {
      if(element.driver_health_status == 2){
        this.executedOrder++
      }
      if(element.driver_health_status == 3){
        this.refusedOrder++
      }

      if(element.driver_health_status == 3 && element.breathalyzer > 0){
        this.refusedBreathalyzer++
      }
    });

    let orderCount = this.driverInfo.driver_orders.length
    this.count = orderCount;
    if(orderCount){
      this.executedOrderPercent = (this.executedOrder / orderCount) * 100
      this.refusedOrderPercent = (this.refusedOrder / orderCount) * 100
      this.refusedBreathalyzerPercent = (this.refusedBreathalyzer / orderCount) * 100
    }

  },
 }
</script>

<style scoped>
.main {
  background: #ffffff;
  border-radius: 20px;
  width: 48%;
  padding: 20px;
  height: 322px;
}
.driver__info{
  font-weight: 400;
  font-size: 20px;
  line-height: 24px; 
}
.driver__analitic {
  height: 200px;
}

.driver__analitic-items{
  width: 40%;
}
.driver__analitic-item{
  padding-bottom: 25px;
}

.span1 {
  font-weight: 500;
  font-size: 20px;
  line-height: 18px;
  margin-left: 5px;
}
.driver__analitic-span1{
  margin-left: 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 12px;
}
.driver__analitic-span2 {
  font-weight: 400;
  font-size: 14px;
  line-height: 12px;
  color: #ACACAC;
}

.rect1 {
  left: 0;
  top: 0;
}
</style>