<template>
    <div>
        <Spinner />
        <SubHeaderBlock />
        <div class="flex mt-3 info">
          <InfoBlock   v-if="driver_data" />
          <AnaliticsBlock  v-if="driver_data" />
       </div>
        <TableBlock  v-if="driver_data"  class="table_block"/>
    </div>
</template>

<script>
 import SubHeaderBlock from "./driverComponents/subHeader"
 import InfoBlock from "./driverComponents/info"
 import AnaliticsBlock from "./driverComponents/analitics"
 import TableBlock from "./driverComponents/table"
 import Spinner from "../../../components/spinner/index.vue";

 import { mapState } from "vuex"

   export default {
    components: {
      SubHeaderBlock,
      InfoBlock,
      AnaliticsBlock,
      TableBlock,
      Spinner,
    },

    data() {
      return {
        driver_data : null
      }
    },
    computed: {
      ...mapState(['driverInfo'])
    },

    async beforeCreate() {
      await this.$store.dispatch('getDriverInfo', this.$route.params.id);
      if(this.driverInfo[0]){
        this.driver_data = this.driverInfo;
      }
    },
  }

</script> 

<style scoped> 
.info{
  width: 90%;
  margin: auto;
}

.table_block {
  width: 90%;
  margin: auto;
  padding: 0;
}
</style>