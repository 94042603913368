<template>
  <div class="sub-header">
    <div class="sub-header__wrap container flex">
      <h1 class="sub-header__title main-title ">{{ driverName }}</h1>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      driverName: ''
    };
  },
  computed: {
    ...mapState(['driverInfo'])
  },
  mounted() {
    if( this.driverInfo[0] ){
      this.driverName = this.driverInfo[0].name
    }
  },
};
</script>

<style scoped>
.sub-header {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #fff;
  height: 70px;
  margin-left: 0;
}
.sub-header__title {
  font-weight: 500;
  font-size: 36px;
  line-height: 46px;
}
</style>
