<template>
  <div class="main"> 
    <div>
        <h3 class="driver__info">Данные водителя</h3>
        <hr>
        <div class="driver__info-block flex">
            <img :src="require('@/assets/png/i1.png')" alt="">
            <table class="popup__table">
            <template v-if="items">
              <tr class="popup__table-items">
                <td class="popup__table-item1">ФИО</td>
                <td class="popup__table-item2">{{ items.name }}</td>
              </tr>
              <tr class="popup__table-items">
                <td class="popup__table-item1">ИИН</td>
                <td class="popup__table-item2">{{ items.iin }}</td>
              </tr>
              <tr class="popup__table-items">
                <td class="popup__table-item1">Табельный номер</td>
                <td class="popup__table-item2">{{ items.iin }}</td>
              </tr>
              <tr class="popup__table-items">
                <td class="popup__table-item1">Контактный номер</td>
                <td class="popup__table-item2"> +{{ items.phone }}</td>
              </tr>
              <tr class="popup__table-items">
                <td class="popup__table-item1">Должность</td>
                <td class="popup__table-item2">{{ items.position }}</td>
              </tr>
              <tr class="popup__table-items">
                <td class="popup__table-item1">Город</td>
                <td class="popup__table-item2">{{ items.city_name }}</td>
              </tr>
            </template>  
        </table>
        </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

 export default {
  data() {
    return {
      items: null
    }
  },
  computed: {
        ...mapState(['driverInfo'])
      },
  mounted() {
          if( this.driverInfo){
            this.items = this.driverInfo[0]        
         }
      },
 }
</script>

<style scoped>
.main {
  background: #ffffff;
  border-radius: 20px;
  width: 50%;
  padding: 20px;
  height: 322px;
}

.driver__info{
  font-weight: 400;
  font-size: 20px;
  line-height: 24px; 
}
img {
  border-radius: 15px;
  width: 200px;
  height: 200px;
}

.popup__table {
  padding: 0;
  width: 60%;
}

.popup__table-items {
  width: 100%;
  margin-top: 0px;
}

.popup__table-item1 {
  width: 30%;
  color: #ACACAC;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  padding-bottom: 15px;
}
.popup__table-item2 {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  padding-bottom: 15px;
}
</style>